<template>
  <div class="product_box cart_product_box order_product_box flex-r-c-c" style="min-height:0">
    <div class="pro_info flex-r-s-s">
      <van-image width="2.13rem" height="2.13rem" fit="cover" :src="myinfo.proIcon" />
      <div class="cart_pro_info ">
        <div class=" pro_name  van-multi-ellipsis--l2 flex-r-s-s" style="width:6rem;margin-top:0">
          <div class="flex-r-s-c">
            <div v-if="myinfo.giveFlag === 1" class="pro_zeng">赠</div>
            {{ myinfo.proName }}
          </div>
        </div>
        <div class="pro_brand_model van-ellipsis">{{ myinfo.proBrand }} | {{ myinfo.proModel }}</div>
        <div class="pro_bottom flex-r-sb-c" style="width:93%">
          <div class="pro_money">￥<span
            style="font-size: 0.49rem;"
          >{{ myinfo.showPrice === 0? myinfo.proPrice :'详询客服' }}</span></div>
          <div class="pro_num_change">
            <van-stepper v-model="myinfo.proNumber" :step="myinfo.minQuantity" :min="myinfo.minQuantity" integer
                         :max="mycanBuyNum" input-width="1rem" button-size="0.5rem" @blur="changeNum"
                         @minus="changeNumminus" @plus="changeNumplus"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mallPreferential_addCart, mallPreferential_delCart } from '@/api/pro'
export default {
  props: {
    info: { type: Object, default: () => { } },
    pmid: { type: String, default: '' },
    type: { type: String, default: '' },
    kind: { type: String, default: '1' },
    cannum: { type: Number, default: null }
  },
  data() {
    return {
      myinfo: this.info,
      mycanBuyNum: this.cannum === -1 ? 9999 : this.cannum
    }
  },
  methods: {
    // 修改数量
    changeNum() {
      var val = this.myinfo.proNumber
      console.log('changeNum', val)
      var minQuantity = parseInt(val) % parseInt(this.myinfo.minQuantity) === 0
      console.log(minQuantity)
      if (parseInt(val) > 0 && minQuantity) {
        mallPreferential_addCart({ productId: this.myinfo.productId, productModuleId: this.pmid, num: val }).then(res => {
          this.$utils.debounce(() => {
            this.$emit('changeNum', res.data)
          }, 50)()
        })
      } else if (val === '') {
        console.log('changeNum kong')
      } else {
        mallPreferential_delCart({ productId: this.myinfo.productId, productModuleId: this.pmid }).then(res => { if (res.data === 0) { this.$router.push('/order/shiplist_fastCart') } })
        this.myinfo.proNumber = 0
      }
      this.$forceUpdate()
    },
    changeNumplus() {
      var val = this.myinfo.proNumber * 1 + this.myinfo.minQuantity * 1
      mallPreferential_addCart({ productId: this.myinfo.productId, num: val, productModuleId: this.pmid }).then(res => {
        this.$utils.debounce(() => {
          this.$emit('changeNum', res.data)
        }, 50)()
      })
    },
    changeNumminus() {
      var val = this.myinfo.proNumber * 1 - this.myinfo.minQuantity * 1
      mallPreferential_addCart({ productId: this.myinfo.productId, num: val, productModuleId: this.pmid }).then(res => {
        this.$utils.debounce(() => {
          this.$emit('changeNum', res.data)
        }, 50)()
      })
    }
  }
}
</script>
<style>
.pro_name_posite {
  position: absolute;
  top: 0;
}
</style>
