<template>
  <div class="page_bg">
    <!-- 顶部 card-->
    <div class="bg_top_linear-gradient">
      <!-- 顶部返回 -->
      <div class="back_top_name flex-r-sb-c">
        <van-icon name="arrow-left" @click="backPath" />
        <div class="top_name">提交订单</div>
        <div class="top_icon" />
      </div>
    </div>
    <!-- 地址选择 -->
    <div v-if="order_info.address" class="order_address_box flex-r-s-c"
         :style="{ minHeight : order_info.address.name? '2.3rem':'1.5rem' }"
         @click="changeAddress(order_info.address.id)"
    >
      <van-image width="0.69rem" height="0.69rem" src="https://oss.xiaoyi120.com/shop2.0/order/address_icon.png"
                 style="margin-left: 0.3rem;"
      />
      <div class="order_address_msg flex-c-s-s" style="margin-left: 0.3rem;">
        <div class="address_msg_name">{{ order_info.address.name || '还没选择地址，' }}
          {{ order_info.address.tel || '添加一个吧！' }}
        </div>
        <div v-if="order_info.address.name" class="address_msg_addres van-multi-ellipsis--l2">
          地址：{{ order_info.address.address }}
        </div>
      </div>
      <div class="order_address_change flex-r-c-c" style="margin-left: 0.3rem;">
        <span v-if="order_info.address.name">{{ order_info.address.tel ? '修改':'新增' }}</span>
        <span v-else>&nbsp;</span>
        <van-icon name="arrow" />
      </div>
    </div>
    <!-- 商品区域 -->
    <div class="order_pro_box" :style="{ marginTop : order_info.address.name? '-1.52rem':'-2.3rem' }">
      <div v-for="(company_item,company_index) in order_info.companies" :key="company_index" class="cart_company"
           style="margin-bottom:0;width:9.15rem !important;padding-top:0.35rem;margin-left:0.33rem"
      >
        <div class="company_pro_list" style="padding-left:0">
          <div v-for="(pro_item,pro_index) in productsList" :key="pro_index" class="flex-r-s-c">
            <van-swipe-cell style="width:9rem">
              <proCard04 :info="pro_item" :type="type" :pmid="productModuleId" :cannum="order_info.canBuyNum"
                         @changeNum="changeNum"
              />
              <template #right>
                <van-button square type="danger" text="删除" style="height:2.7rem" @click="delPro(pro_item,pro_index)" />
              </template>
              <!-- 优惠信息 -->
              <div v-if="pro_item.resActicityDtos" class="pro_discount_box ">
                <div class="pro_discount_items ">
                  <div v-for="(discounts,discounts_index) in pro_item.resActicityDtos" :key="discounts_index"
                       class="pro_discount_item flex-r-s-s"
                  >
                    <div class="discounts_tag" style="padding-right:0">{{ discounts.actionName }}：</div>
                    <div v-for="(discount,discount_index) in discounts.actions" :key="discount_index"
                         class="discounts_tag van-ellipsis" style="padding-left:0"
                    >
                      {{ discount }}
                    </div>
                  </div>
                </div>
              </div>
            </van-swipe-cell>
          </div>

        </div>
      </div>
      <!-- 订单备注区域 -->
      <div class="order_remark_box">
        <van-cell-group>
          <van-field v-model="remark" label="备注:" placeholder="选填，请和商家协商一致" @input="inputremark" />
        </van-cell-group>
      </div>
    </div>

    <!-- 是否需要货单区域 -->
    <div class="order_trank_box flex-r-sa-c">
      <div :class="isNeed === 1 ? 'is_need_trank2 is_need_trank_actived1':'is_need_trank2'" @click="isNeedTrank(1)">
        <div>需要货单</div>
      </div>
      <div :class="isNeed === 0 ? 'is_need_trank2 is_need_trank_actived2':'is_need_trank2'" @click="isNeedTrank(0)">
        <div>不要货单</div>
      </div>
    </div>
    <!-- 支付方式 -->
    <div class="order_payway_box ">
      <div class="payway_title">支付方式</div>
      <div class="payway_types">
        <div class="payway_type_item flex-r-sb-c">
          <div class="type_item_left  flex-r-s-c">
            <van-image width="0.48rem" height="0.48rem" src="https://oss.xiaoyi120.com/shop2.0/order/pay_way_01.png" />
            <div class="type_item_name">在线支付</div>
          </div>
          <div class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem">微信支付</span>
            <!-- <van-checkbox v-model="payWay.wechat" checked-color="#0767AB" icon-size="0.45rem"
                          :disabled="order_info.onlyATL" @change="changePayWay"
            /> -->
            <van-radio-group v-model="radio">
              <van-radio name="1" checked-color="#0767AB" icon-size="0.45rem" />
            </van-radio-group>
          </div>
        </div>
        <van-divider v-if="!order_info.onlyATL" />
        <div v-if="!order_info.onlyATL" class="payway_type_item flex-r-sb-c" style="margin-bottom:0.3rem">
          <!-- <div class="payway_type_item flex-r-sb-c" style="margin-bottom:0.3rem"> -->
          <div class="type_item_left  flex-r-s-c">
            <van-image width="0.48rem" height="0.48rem" src="https://oss.xiaoyi120.com/shop2.0/order/pay_way_02.png" />
            <div class="type_item_name">账期结算</div>
          </div>
          <div class="type_item_right  flex-r-s-c" @click="payBill">
            <span v-if="order_info.billCase" style="margin-right:0.21rem">{{ order_info.billCase.mouth }}个月 |
              {{ order_info.billCase.way }}
            </span>
            <span v-else style="margin-right:0.21rem">请选择账期方案</span>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
    <!-- 结算金额 -->
    <div class="order_check_box order_payway_box">
      <div class="check_title">结算金额</div>
      <div class="payway_types">
        <div class="payway_type_item flex-r-sb-c">
          <div class="type_item_left check_item_left  flex-r-s-c">
            <div class="check_item_name">商品原价</div>
          </div>
          <div class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem">￥{{ order_info.originPrice }}</span>
          </div>
        </div>
        <van-divider v-if="(order_info.confirmActionAmount*1)" />
        <div class="payway_type_item flex-r-sb-c">
          <div class="type_item_left check_item_left  flex-r-s-c" style="height:0.7rem" @click="openRule">
            <div class="check_item_name">商品运费 </div>
            <div>
              <van-icon name="question-o" style="margin-top:0rem" color="#999999" size="0.45rem" />
            </div>
          </div>
          <div v-if="(parseInt(type) === 4 || order_info.companies[0].companyId != '1000' || showPacket) && showPacket"
               class="type_item_right  flex-r-s-c"
          >
            <span
              style="margin-right:0.21rem;text-decoration:line-through;color: #ED0D0D;"
            >￥{{ order_info.originalExpressPrice }}</span>
            <span style="margin-right:0.21rem;">￥{{ order_info.expressPrice }}</span>
          </div>
          <div v-else class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem;">￥{{ order_info.expressPrice }}</span>
          </div>
        </div>
        <van-divider v-if="(order_info.confirmActionAmount*1)" />
        <div v-if="(order_info.confirmActionAmount*1)" class="payway_type_item flex-r-sb-c">
          <div class="type_item_left check_item_left  flex-r-s-c">
            <van-image width="0.43rem" height="0.37rem" src="https://oss.xiaoyi120.com/shop2.0/order/pay_minu.png" />
            <div class="check_item_name">活动满减</div>
          </div>
          <div class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem;color:#ED0D0D;">￥{{ order_info.confirmActionAmount }}</span>
          </div>
        </div>
        <van-divider v-if="order_info.enableCoupons&&order_info.enableCoupons.length>0" />
        <div v-if="order_info.enableCoupons&&order_info.enableCoupons.length>0" class="payway_type_item flex-r-sb-c"
             style="margin-bottom:0.3rem"
        >
          <div class="type_item_left check_item_left flex-r-s-c">
            <van-image width="0.43rem" height="0.37rem" src="https://oss.xiaoyi120.com/shop2.0/order/pay_coupon.png" />
            <div class="check_item_name">优惠券</div>
          </div>
          <div class="type_item_right  flex-r-s-c" @click="payCoupon">
            <span v-if="couponItem"
                  style="margin-right:0.21rem;color:#ED0D0D;"
            >{{ couponItem.couponClass === 2 ? couponItem.discountNum+'折':'-￥'+couponItem.money }}
            </span>
            <span v-else style="margin-right:0.21rem">{{ order_info.enableCoupons.length }}张可用
            </span>
            <van-icon name="arrow" />
          </div>
        </div>
        <!-- vip -->
        <van-divider
          v-if=" order_info.formType !== 3 && order_info.formType !== 4 && parseInt(order_info.discount) < 100"
        />
        <div v-if="order_info.formType !== 3 &&order_info.formType !== 4 && parseInt(order_info.discount) < 100"
             class="payway_type_item flex-r-sb-c"
        >
          <div class="type_item_left check_item_left  flex-r-s-c" style="height:0.7rem">
            <van-image width="0.43rem" height="0.37rem" src="https://oss.xiaoyi120.com/shop2.0/vip/vip_sub_icon.png" />
            <div class="check_item_name">{{ order_info.mallVipLevelName }}享{{ order_info.discount }}折</div>

            <van-popover v-model="showPopover" theme="dark" placement="top" trigger="click">
              <div style="font-size: 0.35rem;color: #fff;padding: 0.2rem;">会员折扣权益，享满减用券价后折上折</div>
              <template #reference>
                <van-icon name="question-o" style="margin-top:0rem" color="#999999" size="0.45rem" />
              </template>
            </van-popover>
          </div>
          <div class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem;color:#ED0D0D;">￥{{ vipMoney }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部提交bar -->
    <orderSub ref="orderSub" :retotal="retotal" :coupon="totalDiscount" @subOrder="subOrder" />
    <!-- 优惠券选择 -->
    <van-popup v-model="couponShow" round position="bottom" :style="{ height: '40%' }">
      <div class="order_coupon_list">
        <van-radio-group v-model="couponRadio">
          <van-radio v-for="(coupon,coupon_index) in order_info.enableCoupons" :key="coupon_index"
                     checked-color="#0767AB" :name="coupon.couponId" @click="chooseCoupon(coupon)"
          >
            <van-image v-if="coupon.mergeType === 0" width="8.93rem" height="2.29rem" :src="coupon.icon" />
            <div v-if="coupon.mergeType === 1" class="hecheng_coupon flex-r-s-c">
              <div class="pirce" style="color: #ED0D0D;">
                <span style="font-size: 0.37rem;">￥</span>{{ coupon.money }}
              </div>
              <div class="msg flex-c-s-s">
                <div style="font-size: 0.37rem;font-weight: 700;color: #333;">商城通用现金券</div>
                <div style="font-size: 0.32rem;color: #333;">满{{ coupon.atLeast }}可用</div>
              </div>
            </div>
          </van-radio>
        </van-radio-group>
      </div>
      <div class="order_coupon_btn">
        <van-button round color="#0767AB" class="coupon_btn_cancer" @click="chooseCoupon(null)">不使用优惠券</van-button>
      </div>
    </van-popup>
    <!-- loading遮罩层 -->
    <van-overlay :show="overlayShow" @click="overlayShow = false">
      <div class="overlay_loading">
        <van-loading color="#eee" size="0.6rem" type="spinner" vertical>订单加载中...</van-loading>
      </div>
    </van-overlay>

    <!-- 运费tips -->
    <van-popup v-model="express_show" round position="bottom" closeable close-icon="close" :style="{ height: '16rem' }">
      <div class="popup_bg flex-c-c-c">
        <div class="popup_bg_title">商品运费规则</div>
        <div class="popup_bg_body flex-c-c-c">
          <div class="popup_bg_body_bg body1 flex-c-c-s">
            <div class="title">一、运费规则</div>
            <div class="msg">订单满足以下情况，可享受包邮服务，免邮费哦。</div>
            <div class="msg">
              <van-image width="8.93rem" height="5.87rem"
                         src="https://hxxxkj-oss-cdn.xiaoyi120.com/sysx-xcx/mall/express_table.png"
              />
            </div>
            <div class="msg" style="color: #d2541c;margin-top: 0.21rem">*不满足则需支付10元/单运费。</div>
          </div>
          <div class="popup_bg_body_bg body2 flex-c-c-s">
            <div class="title">二、默认顺丰快递</div>
            <div class="msg">如有特殊需求，请联系客服。</div>
          </div>
          <div class="popup_bg_body_bg body1 flex-c-c-s">
            <div class="title">三、退运费规则</div>
            <div class="msg">若出现用户在较短时间内连续下单，仓库合并<span style="color: #d2541c;">多笔订单为一个包裏发货</span>"的情况，系统将在合并订单后，自动将<span
              style="color: #d2541c;"
            >多收的运费</span>按<span
              style="color: #d2541c;"
            >原路退回</span>至支付账户，请注意查收；如有疑问，请联系客服。</div>
          </div>
        </div>

        <!-- <van-image width="9.25rem" height="10rem" src="https://oss.xiaoyi120.com/shop2.0/order/express_rule_msg3.png" /> -->
      </div>
    </van-popup>
  </div>
</template>

<script>
import './index.scss'
import './../cart/index.scss'
import orderSub from '@/components/bar/order_sub'
import proCard04 from '@/components/pro_card_04_cart'
import { submitOrderUnion, payStudy, findAddress, confirmExpressPrice } from '@/api/order'
import { mallPreferential_orderPreview, mallPreferential_delCart } from '@/api/pro'
export default {
  name: 'SubOrderCart',
  components: { proCard04, orderSub },
  data() {
    return {
      productModuleId: '',
      certificationDecide: null,
      couponShow: false,
      overlayShow: false,
      pid: '',
      pnum: '',
      cid: '1000',
      type: '',
      item_info: [],
      order_info: {},
      productsList: [],
      shopinglistId: '',
      remark: '',
      payWay: { wechat: true },
      radio: '1',
      couponRadio: '',
      couponItem: null,
      totalDiscount: null,
      retotal: 0,
      vipMoney: 0,
      isNeed: 1,

      isBillShow: 0,
      timeLimitActivityType: false,
      showPopover: false,
      showPacket: false,
      userIdentityFlag: false,
      express_show: false,

      isOnlyColdPro: false,

      checkProIDs: []
    }
  },
  created() {
    this.productModuleId = this.$route.query.productModuleId
    if (sessionStorage.getItem('timeLimitActivityType')) { this.timeLimitActivityType = true }
    this.getInfo()
    this.overlayShow = true
  },
  methods: {
    backPath() { window.history.back() },
    // 获取订单内容
    getInfo() {
      this.productsList = []
      mallPreferential_orderPreview({ productModuleId: this.productModuleId }).then(res => {
        this.order_info = res.data
        this.productsList = res.data.companies[0].products
        // 地址的获取
        if (sessionStorage.getItem('address')) {
          this.order_info.address = JSON.parse(sessionStorage.getItem('address'))
          // 订单运费 金额获取
          const express_prams = {
            coldOriginPrice: res.data.coldOriginPrice, commonOriginPrice: res.data.commonOriginPrice, confirmActionAmount: res.data.confirmActionAmount,
            confirmOrderId: res.data.confirmOrderId, confirmType: this.type, couponId: '', discount: res.data.discount, originPrice: res.data.originPrice,
            userReceiptAddressId: this.order_info.address.id
          }
          confirmExpressPrice(express_prams).then(res_express => {
            this.$set(this, 'retotal', res_express.data.confirmPrice)
            this.$set(this, 'vipMoney', res_express.data.vipPrice)
            this.$set(this.order_info, 'originalExpressPrice', res_express.data.originalExpressPrice)
            this.$set(this.order_info, 'expressPrice', res_express.data.expressPrice)
            this.$set(this, 'showPacket', res_express.data.originalExpressPrice * 1 > 0)
            var totalDiscount = res_express.data.vipPrice * 1 + res_express.data.confirmActionAmount * 1 + res_express.data.couponPrice * 1
            this.$set(this.order_info, 'totalDiscount', totalDiscount.toFixed(2))
          })
        } else {
          findAddress({ productModuleFlag: 0, productModuleId: '' }).then(res_address => {
            const parms = {
              id: res_address.data.userReceiptAddressId,
              name: res_address.data.receiptPersonName,
              tel: res_address.data.receiptMobile,
              address: res_address.data.province.provinceName + res_address.data.city.cityName + res_address.data.area.areaName +
                res_address.data.receiptAddress
            }
            this.$set(this.order_info, 'address', parms)
            // 订单运费 金额获取
            const express_prams = {
              coldOriginPrice: res.data.coldOriginPrice, commonOriginPrice: res.data.commonOriginPrice, confirmActionAmount: res.data.confirmActionAmount,
              confirmOrderId: res.data.confirmOrderId, couponId: '', discount: res.data.discount, originPrice: res.data.originPrice,
              userReceiptAddressId: res_address.data.userReceiptAddressId
            }
            confirmExpressPrice(express_prams).then(res_express => {
              this.$set(this, 'retotal', res_express.data.confirmPrice)
              this.$set(this, 'vipMoney', res_express.data.vipPrice)
              this.$set(this.order_info, 'originalExpressPrice', res_express.data.originalExpressPrice)
              this.$set(this.order_info, 'expressPrice', res_express.data.expressPrice)
              this.$set(this, 'showPacket', res_express.data.originalExpressPrice * 1 > 0)
              var totalDiscount = res_express.data.vipPrice * 1 + res_express.data.confirmActionAmount * 1 + res_express.data.couponPrice * 1
              this.$set(this.order_info, 'totalDiscount', totalDiscount.toFixed(2))
            })
          })
        }
        // 代发用户的判断
        this.userIdentityFlag = parseInt(res.data.userIdentity) === 1
        // 备注的获取
        if (sessionStorage.getItem('orderRemark')) { this.remark = sessionStorage.getItem('orderRemark') }
        // 账期的选择
        if (sessionStorage.getItem('billCase')) {
          this.order_info.billCase = JSON.parse(sessionStorage.getItem('billCase'))
          this.payWay.wechat = false
        } else {
          this.payWay.wechat = true
        }
        this.overlayShow = false
        this.$forceUpdate()
      })
    },
    // 修改地址
    changeAddress(addressId) {
      this.$router.push('/user/addressList?type=2&aId=' + addressId + '&pmid=' + this.productModuleId)
    },
    // 删除商品
    delPro(item, index) {
      mallPreferential_delCart({ productId: item.productId, productModuleId: this.productModuleId }).then(res => {
        this.$toast({ message: '删除成功！', duration: 1 * 1000 })
        sessionStorage.setItem('cart_pro_detail', 1)
        this.$nextTick(() => { this.getInfo() })
        if (res.data === 0) {
          this.$router.replace('/order/shiplist_fastCart?productModuleId=' + this.productModuleId)
        }
      })
    },
    // 修改备注
    inputremark(e) { sessionStorage.setItem('orderRemark', e) },
    // 货单是否需要
    isNeedTrank(item) { this.isNeed = item },
    // 修改商品数量
    changeNum(num) {
      sessionStorage.setItem('cart_pro_detail', 1)
      sessionStorage.setItem('orderProNum', num)
      this.overlayShow = true
      this.$nextTick(() => { this.getInfo() })
    },
    openRule() { this.express_show = true },
    // 选择微信支付
    changePayWay(e) {
      if (e) {
        sessionStorage.removeItem('billCase')
        this.$set(this.order_info, 'billCase', null)
        this.$forceUpdate()
      }
    },
    // 账期选择
    payBill() {
      this.$router.push('/order/billPay?price=' + this.order_info.originPrice + '&flag=3')
    },
    // 优惠券选择
    payCoupon() { this.couponShow = true },
    // 选中优惠券
    chooseCoupon(coupon) {
      console.log('coupon', coupon)
      var couponId = ''
      if (coupon) { couponId = coupon.couponId }
      // 订单运费 金额获取
      const express_prams = {
        coldOriginPrice: this.order_info.coldOriginPrice, commonOriginPrice: this.order_info.commonOriginPrice, confirmActionAmount: this.order_info.confirmActionAmount,
        confirmOrderId: this.order_info.confirmOrderId, couponId: couponId, discount: this.order_info.discount, originPrice: this.order_info.originPrice,
        userReceiptAddressId: this.order_info.address.id
      }
      confirmExpressPrice(express_prams).then(res_express => {
        this.$set(this, 'retotal', res_express.data.confirmPrice)
        this.$set(this, 'vipMoney', res_express.data.vipPrice)
        this.$set(this.order_info, 'originalExpressPrice', res_express.data.originalExpressPrice)
        this.$set(this.order_info, 'expressPrice', res_express.data.expressPrice)
        this.$set(this, 'showPacket', res_express.data.originalExpressPrice * 1 > 0)
        var totalDiscount = res_express.data.vipPrice * 1 + res_express.data.confirmActionAmount * 1 + res_express.data.couponPrice * 1
        this.$set(this.order_info, 'totalDiscount', totalDiscount.toFixed(2))
      })
      this.couponShow = false
      this.couponItem = coupon
    },
    // 提交订单
    subOrder() {
      console.log('this.order_info', this.order_info)
      this.$toast.loading({ message: '订单提交中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      var list = this.order_info.companies
      var companies = []
      for (const i in list) {
        var company = {
          companyId: list[i].companyId,
          quoteStagingKey: this.order_info.billCase ? this.order_info.billCase.quoteStagingKey : '',
          shoppinglistId: this.shopinglistId, // 货单id
          products: [],
          remark: this.remark
        }
        for (const j in list[i].products) {
          const pro = {
            buyNumber: list[i].products[j].proNumber,
            productId: list[i].products[j].productId,
            timeLimitActivityType: this.timeLimitActivityType ? 3 : ''
          }
          company.products.push(pro)
        }
        companies.push(company)
      }

      const parms = {
        orderCategory: this.payWay.wechat ? 1 : 3,
        userReceiptAddressId: this.order_info.address.id,
        confirmType: 6,
        couponId: this.couponItem ? this.couponItem.couponId : '',
        typeNo: this.payWay.wechat ? '1' : '3',
        followPdfPrint: this.isNeed,
        companies: companies,
        productModuleId: this.productModuleId,
        productModuleProductIdList: this.checkProIDs

      }
      if (!this.userIdentityFlag) { parms.followPdfPrint = 0 }
      console.log('parms', parms)
      submitOrderUnion(parms).then(res => {
        if (this.payWay.wechat) {
          this.$set(res.data, 'type', 1)
          // 调起支付
          payStudy(res.data)
        } else {
          this.$router.push('/order/payResult?orderId=' + res.data.orderId + '&status=0')
          this.$toast.clear()
        }
        sessionStorage.removeItem('orderRemark')
        sessionStorage.removeItem('billCase')
        sessionStorage.removeItem('timeLimitActivityType')
        sessionStorage.removeItem('orderProNum')
      })
      // .catch(() => {
      //   sessionStorage.removeItem('billCase')
      //   sessionStorage.removeItem('timeLimitActivityType')
      // })
    }
  }
}
</script>

<style>
.company_pro_list .van-swipe-cell__right {
  right: -0.1rem;
}
</style>
